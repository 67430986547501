<template>
    <div class="card card-height">
        <div class="table-container">
            <table class="table create-billing table-width">
                												
                <thead>
                    <tr>
                        <th class=" pl-10 particular">Item Details</th>
                        <th>Stock Balance</th>
                        <th class="qty">Qty</th>
                        <th class="rate">Rate</th>
                        <th class="sub-amount">Amount</th>
                        <th class="discount">Dis(%)</th>
                        <th class="d-amount">Dis</th>
                        <th class="after-amount">After Discount</th>
                        <th class="vat">VAT(%)</th>
                        <th class="vat-amount">VAT Amount</th>
                        <th class="total">Total Amount</th>
                        <th class="th-action"></th>
                    </tr>
                </thead>

                <tbody ref="tableRef">
                    <RowInline 
                        v-for="(item, i) in tableItems" :key="item.id"
                        :item="item" :index="i"
                        :vatRate="vatRate"
                        @onOpenEditItem="onOpenEditItem"
                        @onDeleteItem="onDeleteItem"
                    />

                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>

                </tbody>
            </table>
        <p v-if="!tableItems.length" class="text-center mt-5">No data</p>
    </div>

    <div class="row">

        <div class="col-12 mb-2" v-if="!isHideAddBtn">
            <div class="add-new-line">
                <button @click="$emit('addNewItem')" class="btn btn-outline-primary btn-sm">
                    <i class="fas fa-plus"></i>
                    Add new
                </button>
            </div>
        </div>
        <div class="col-md-5">
            <MoneyReceiptForm :grand-total="parseFloat(grandTotal)" :isHospitalInvoice="true" ref="moneyReceiptForm" />
        </div>
        <div class="col-md-5 ml-auto">
            <div>	
                <table class="table create-billing border table-totals">
                    <tbody>
                        <tr>
                            <td class="product px-2">Sub-Total Amount</td>
                            <td class="comma-sep">:</td>
                            <td class="price text-right px-2"> {{ subTotal }}</td>
                        </tr>
                        <tr>
                            <td class="product px-2">Total VAT</td>
                            <td class="comma-sep">:</td>
                            <td class="price text-right px-2"> {{ totalVAT }}</td>
                        </tr>
                        <tr>
                            <td class="product px-2">Total Trade Discount</td>
                            <td class="comma-sep">:</td>
                            <td class="price text-right px-2"> {{ getAmountAfterDiscount }}</td>
                        </tr>
                        <tr>
                            <td class="product px-2">Total Bill Amount</td>
                            <td class="comma-sep">:</td>
                            <td class="price text-right px-2"> {{ total }}</td>
                        </tr>
                        <tr>
                            <td class="product px-2 d-flex w-100">Discount / Round up Adj
                                <div class="price-adj">
                                    <span class="mr-5"></span>
                                    <input
                                        v-model.trim="discountPercent"
                                        type="number" class="form-control form-control-sm discount-adj ml-auto text-right"
                                        placeholder="%" :min="0"
                                    >
                                </div>
                            </td>
                            <td class="comma-sep">:</td>
                            <td class="price">
                                <div class="price-adj px-2">
                                    <span class="mr-5"></span>
                                    <input
                                        v-model.trim="discountAmount"
                                        type="number" class="form-control form-control-sm discount-adj ml-auto text-right"
                                        placeholder="amount" :min="0"
                                    >
                                </div>
                            </td>
                        </tr>
                        <tr v-if="discountAmount > 0">
                            <td class="product px-2">Select discount head</td>
                            <td class="comma-sep">:</td>
                            <td style="font-weight: normal">
                                <v-select
                                    placeholder="Select Discount Head"
                                    v-model="formData.discount_head_id"
                                    :options="salesAccountHeads"
                                    label="name"
                                    :reduce="name => name.id"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td class="product px-2">Net Bill</td>
                            <td class="comma-sep">:</td>
                            <td class="price text-right px-2">
                                <input
                                    v-model.trim="grandTotal"
                                    type="number"
                                    class="form-control form-control-sm discount-adj ml-auto text-right"
                                    placeholder="amount"
                                    :min="0"
                                    @keyup="setDiscountAmount"
                                >
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

    </div>
    

</div>
</template>

<script setup>    
    import RowInline from '@/components/molecule/company/hospital/general-billing/InvoiceTableRow.vue';
    import useDetectOutsideClick from '@/services/useDetectOutsideClick';
    import { computed, ref, watch, inject } from 'vue';
    import MoneyReceiptForm from "@/components/molecule/company/hospital/billing/MoneyReceiptForm.vue";
    
    const tableRef = ref(null)
    const discountAmount = ref(null);
    const discountPercent = ref(null);
    const moneyReceiptForm = ref(null);
    const totals = inject('totals');
    const formData = inject("formData");

    const props = defineProps({
        tableItems: {
            type: Array
        },
        addNewItem: {
            type: Function
        },
        salesAccountHeads: {
            type: Array
        },
        vatRate: {
            type: Array
        },
        subTotalVal: {
            type: Number
        },
        totalVatVal: {
            type: Number
        },
        totalVal: {
            type: Number
        },
        isHideAddBtn: {
            type: Boolean,
            default: false
        }
    })

    // const grandTotal = computed(() => total.value - discountAmount.value)
    const grandTotal = ref(0);
    const tableData = computed(() => props.tableItems)

    watch(discountAmount, () => {
        grandTotal.value = total.value - discountAmount.value
        formData.value.adjustment_amount = discountAmount.value
        grandTotal.value = total.value - discountAmount.value
    })

    watch(discountPercent, () => {
        if(discountPercent.value === 0){
            return discountAmount.value = 0;
        } 
        return discountAmount.value = (total.value * discountPercent.value) / 100;
    })

    watch(tableData, () => {
        if(tableData.value.length < 1){
            moneyReceiptForm.value.generateMoneyReceiptNumber()
            discountAmount.value = null;
            discountPercent.value = null;
        }
    })

    const subTotal = computed(() => {
        let subTotal=0
        props.tableItems.map(item => {
            subTotal += parseFloat(item.quantity * item.rate) - parseFloat(item.discount_amount) +  parseFloat(item.vat_amount)
        })
        totals.value.subTotalVal = subTotal.toFixed(4);
        return subTotal.toFixed(4)
    })

    const totalVAT = computed(() => {
        let vat = 0
        props.tableItems.map(item => {
            if(item.vat_amount) {
              vat += parseFloat(item.vat_amount)
            }
        })
        totals.value.totalVatVal = vat.toFixed(4);
        return vat.toFixed(4);
    })

    const getAmountAfterDiscount = computed(() => {
        let discount = 0;
        props.tableItems.map(item => {
            if(item.discount_amount) {
              discount += parseFloat(item.discount_amount);
            }
        })
        return discount.toFixed(4);
    })

    const total = computed(() => {
        var totalValue = parseFloat(subTotal.value + totalVAT.value);
        totals.value.totalVal = totalValue.toFixed(4);
        return totalValue.toFixed(4);
    })

    watch(total, () => {
        grandTotal.value = total.value - discountAmount.value
    })

    const setDiscountAmount = () => {
        discountAmount.value = (total.value - parseFloat(grandTotal.value)).toFixed(4);
    }

    const emit = defineEmits(['addNewItem']);

    useDetectOutsideClick(tableRef, () => {
        resetItemList();
    })
    
    const onOpenEditItem = (index) => {
        resetItemList(index);
    }

    const onDeleteItem = (index) => {
        props.tableItems.splice(index, 1);
    }

    const resetItemList = (selectedIndex = -1) => {
        for(let i = 0; i < props.tableItems.length; i++){
            
            if(selectedIndex === i){
                props.tableItems[i].isEdit = true;
                continue;
            }
            props.tableItems[i].isEdit = false;
        }
    }
</script>

<style>
.price-adj{
    display: flex;
    align-items: center;
}

.discount-adj{
    width: 60%;
}

.create-billing > :not(first-child) > * {
    padding-left: 10px !important;
}

.table-totals{
    font-weight: bold;
}
.product{
    width: 60%;
}
.comma-sep{
    text-align: right;
}

.create-billing > :not(caption) > * > * {
    padding: 0.72rem 0.2rem;
}
.particular{
    width: 25%;
}
.qty{
    width: 6%;
}
.discount{
    width: 5%;
}


.card-height{
    min-height: 200px
}

.add-new-line {
    margin-top: 4%;
}
</style>